import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div className="post-container p-8">
        <div className="blog-post">
          
          <div className="bg-cover bg-center -m-8 mb-6" style={{backgroundImage: `url(${frontmatter.bgImage})`}}>
            <div className="bg-black bg-opacity-50 h-full w-full flex py-24 px-8">
              <h1 className="text-white">{frontmatter.title}</h1>
            </div>
          </div>
          
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        bgImage
      }
    }
  }
`
